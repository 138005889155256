class MainNav {
  constructor(selector) {
    this.element = selector;
    this.init();
  }

  init() {
    window.addEventListener('scroll', () => {
      this.handleScrollWithDebounce();
    });
  }

  handleScrollWithDebounce() {
    const { element } = this;
    const scrolled = window.scrollY;
    let sT = null;

    clearTimeout(sT);
    sT = setTimeout(function () {
      if (scrolled >= 50) {
        element.classList.add('is-scrolled');
      } else {
        element.classList.remove('is-scrolled');
      }
    }, 50);
  }
}

export default MainNav;
