import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

class Gallery {
  constructor(selector) {
    this.gallery = selector;
    this.picturesID = `.${this.gallery.getAttribute('data-pictures-id')}`;
    this.init();
  }

  /* eslint-disable */
  init() {
    let self = this;
    let imgLdd = imagesLoaded( this.gallery, function() {

      let msnry = new Masonry( self.gallery, {
        itemSelector: self.picturesID,
        columnWidth: `${self.picturesID}.grid-sizer`,
        percentPosition: true,
        horizontalOrder: true,
        gutter: 10,
      });
    });

    imgLdd.on('done', function( instance ) {
      console.log('DONE  - all images have been successfully loaded');
    });

    imgLdd.on( 'progress', this.onProgress );
  }

  onProgress(imgLoad, image) {
    const parentPicture = image.img.parentNode;
    parentPicture.classList.remove('is-loading');
    parentPicture.classList.add = image.isLoaded ? '' : 'is-broken';
  }
  /* eslint-enable */
}

export default Gallery;
