/*
  Project: Ewa Kara
  Author: Ewa Karaszkiewicz
 */

import MainNav from './modules/main-nav';
import ToggleNav from './modules/toggle-nav';
import Slider from './modules/slider';
import Accordion from './modules/accordion';
import Gallery from './modules/gallery';

/* eslint-disable */
const toggleNav = document.querySelector('.js-toggle-nav');
if (toggleNav !== null) {
  new ToggleNav(document.querySelector('.js-toggle-nav'));
}

const mainNav = document.querySelector('.c-header');
if (mainNav !== null) {
  new MainNav(document.querySelector('.c-header'));
}
//new ScrollToTop(document.querySelector('.js-scroll-to-top'));
/* eslint-enable */

const sliders = document.querySelectorAll('.js-slider');
if (sliders !== null) {
  for (let i = 0; i < sliders.length; i += 1) {
    /* eslint-disable */
    new Slider(sliders[i]);
    /* eslint-enable */
  }
}

const accordions = document.querySelectorAll('.js-accordion');
if (accordions !== null) {
  for (let i = 0; i < accordions.length; i += 1) {
    /* eslint-disable */
    new Accordion(accordions[i]);
    /* eslint-enable */
  }
}

const galleries = document.querySelectorAll('.js-gallery');
if (galleries !== null) {
  for (let i = 0; i < galleries.length; i += 1) {
    /* eslint-disable */
    new Gallery(galleries[i]);
    /* eslint-enable */
  }
}
