import Glide from '@glidejs/glide';

class Slider {
  constructor(selector) {
    this.element = selector;
    this.args = this.argsAssign();

    this.init();
  }

  init() {
    const slider = new Glide(this.element, this.args);

    slider.mount();
  }

  argsAssign() {
    const classes = this.element.classList;
    let arg = '';

    if (classes.contains('c-hero__slider')) {
      arg = {
        type: 'carousel',
        startAt: 1,
        perView: 1,
        autoplay: 4000,
        animationDuration: 800,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1)',
        bound: true,
        focusAt: 'center',
      };
    }

    if (classes.contains('c-featured-images__slider')) {
      arg = {
        type: 'carousel',
        startAt: 1,
        perView: this.element.getAttribute('data-per-view'),
        autoplay: 4000,
        animationDuration: 800,
        animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1)',
        bound: true,
      };
    }

    return arg;
  }
}

export default Slider;
